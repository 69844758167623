.main-header {
    height: 115px;
    background: url(../../img/header.png);
    background-size: cover;
    border-radius: 40px;
    margin: 40px 48px 10px !important; 
    padding: 0;
}
.navbar-light .navbar-nav .nav-link {
    font-size: 34px;
    height: auto;
}
#logout {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translate(0,-50%);
    font-size: 34px;
    border: 0;
    background: none;
    color: #FFF;
}
#brand {
    background: #FFF;
    border-radius: 40px;
    height: 100%;
    position: relative;
    width: 300px;
    margin-right: 40px;
}
#brand img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    max-height: 90%;
    max-width: 65%;
}
.nav-item a {
    color: #FFF;
    display: flex;
    font-size: 20px;
    margin-right: 30px;
    line-height: 40px;
    height: 40px;
}
.nav-item a img {
    font-size: 40px;
    margin-right: 10px;
}
.nav-item.utenti a img {
    height: 37px;
}
.langChooser {
    display: flex;
}
.langChooser .lang {
    outline: 0;
    padding: 0;
    border: 0;
    background: none;
    margin-right: 15px;
    opacity: .5;
}
.langChooser .lang.selected {
    opacity: 1;
}
.langChooser .lang img {
    width: 30px;
}
.navElements {
    display: flex;
    width: 700px;
    justify-content: space-between;
}
.faIco {
    position: relative;
    height: 40px;
    width: 35px;
    margin-right: 10px;
}

.faIco .fa {
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0,-50%);
}