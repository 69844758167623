code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-element {
  display: block;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  width: 100%;
  font-size: 24px;
  outline: 0;
  border-radius: 10px;
  border: 0;
}

.btn {
  font-size: 30px;
  text-align: center;
  color: #FFF;
  border: 0;
  border-radius: 0;
  padding: 15px 25px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.btn:hover {
  color: #FFF;
}

.btn.slim {
  width: 100%;
  padding: 0;
  height: 52px;
}

.btn.primary {
  background: #26b2b8;
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 18px;
}
.content-wrapper .content .btn.primary {
  background: #013B70;
}
.content-wrapper .content .btn.export {
  background: #889CB5;
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 18px;
}

.btn.secondary {
  background: #879cb4;
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 18px;
}
.main-header {
  border-bottom: 1px solid #9a9a9a;
}

[class*=sidebar-dark-] {
  background-color: #333333;
}

.content-wrapper {
  padding: 10px 48px 10px 48px;
}

.content-wrapper .content h2 {
  color: #013B70;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  padding: 22px 0;
  font-weight: bold;
  margin-bottom: 0;
}
.content-wrapper {
  background: none;
}
.content-wrapper>.content {
  border-radius: 40px;
  padding: 0;
  background: url(./img/logo_trasparente.png) no-repeat right top rgba(255,255,255,.3); 
  background-size: cover;
}
.content-wrapper>.content .inner {
  background: none;
}
.wrapper {
  padding-top: 1px;
  background: linear-gradient(45deg, #019AB8, #28C8BE);
}
.content-wrapper .content .inner {
  padding: 20px;
}

label {
  color: #013B70;
  font-size: 24px;
  text-transform: uppercase;
  margin-top: 20px;
}

.lptour label {
  color: #f06218;
}

.bottoniera {
  margin: 25px 0;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

.tac {
  text-align: center;
}

.tal .btn, .tar .btn {
  display: inline;
}

.hidden {
  display: none;
}
#versione {
  color: #FFF;
  text-align: center;
  display: block;
  font-weight: bold;
  padding-bottom: 25px;
}
@media screen and (max-width: 768px) {
  .content-wrapper {
    padding: 15px;
  }
  .form-element {
    margin-bottom: 15px;
  }
}
body:not(.sidebar-mini-md) .content-wrapper, body:not(.sidebar-mini-md) .main-footer, body:not(.sidebar-mini-md) .main-header {
  margin: 0;
}
iframe {
  display: none !important;
}